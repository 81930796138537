
.container {
  /*border:thin solid red!important;*/
}

/* footer */
.footer{
    /*background-color : #34cc19;*/
    border-top:1px solid #EEEEEE !important;
    /*!*padding:1px;*!*/
    /*margin:3px;*/
    font-size: 14px!important;
    line-height: 1.4!important;
}
.footer div {
    /*background-color : gray;*/
    /*border:thin solid red;*/
}
.footer div ul {
    /*background-color : #1ba203;*/
    /*border:thin solid #9b0315;*/
    padding-top: 15px;
    padding-bottom: 15px;
}
.footer div ul li {
    /*background-color : #a77ead;*/
    /*!*padding:1px;*!*/
    /*!*margin:3px;*!*/
    /*border:thin solid #c205e0;*/
}
.footer div ul li a{
    /*background-color : #c9b359;*/
    text-decoration: none!important;
    color: #000000;
    /*padding:1px;*/
    /*margin:3px;*/
}