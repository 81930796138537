.arrow {
    border: solid #06c;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 6px;
    top: 2px;
    position: relative;
    left: -6px;
}

.right {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
}

.left {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
}

.up {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
}

.down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}
/*hidden arrow into media min 992px*/
@media screen and (min-width: 992px) {
    .arrow{
        visibility: hidden;
        display: none;
    }
}