/*@import "~bootstrap";*/

/*@font-face {*/
/*    font-family: "AvenirLight";*/
/*    font-style: normal;*/
/*    font-weight: normal;*/
/*    src: url("fonts/avenir.eot?#iefix") format("embedded-opentype"), url("fonts/avenir.woff") format("woff"), url("fonts/avenir.ttf") format("truetype"), url("fonts/avenir.svg#AvenirLight") format("svg");*/
/*}*/

/*@font-face {*/
/*    font-family: "AvenirHeavy";*/
/*    font-style: normal;*/
/*    font-weight: normal;*/
/*    src: url("fonts/avenir-heavy.eot?#iefix") format("embedded-opentype"), url("fonts/avenir-heavy.woff") format("woff"), url("fonts/avenir-heavy.ttf") format("truetype"), url("fonts/avenir-heavy.svg#AvenirHeavy") format("svg");*/
/*}*/

.btn-close:focus {
    box-shadow: none !important;
}
.dropdown-divider{
    display: block !important;
    border-top: 1px solid #cccc !important;
}

.dropdown-item:focus, .dropdown-item:hover{
    border-radius: 2px !important;
    box-shadow: 0px 0px 1px !important;
    background-color: #EEEEEE !important;
}

