#bigmenu-tirroir {
    width: 640px;
    position: absolute;
    top: 0px;
    border: 0;
}
#bigmenu-tirroir .close, #bigmenu-tirroir .open{
    -webkit-animation: slide 0.5s forwards;
    -webkit-animation-delay: 2s;
    animation: slide 0.5s forwards;
    animation-delay: 2s;
    left: unset;
    z-index: 1000 !important;
}
#bigmenu-tirroir .open{
    left: 0px !important;
}
#bigmenu-tirroir .close{
    right: 4px !important;
}
#tiroir_btn {
    position: absolute;
    top: 285px;
    left: 0px;
    font-weight: 500;
    float: left;
    padding: 10px;
    text-transform: uppercase;
    min-height: 23px;
    width: 100px;
    text-align: center;
    z-index: 8;
    cursor: pointer;
    font-size: 14px;
    line-height: 1.42857;
    filter: progid:DXImageTransform.Microsoft.Matrix(M11=6.123233995736766e-17, M12=1, M21=-1, M22=6.123233995736766e-17, SizingMethod='auto expand') !important;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.5);
    -moz-transform: rotate(270deg);
    -moz-transform-origin: left top 0;
    -ms-transform: rotate(270deg);
    -ms-transform-origin: left top 0;
    -webkit-transform: rotate(270deg);
    -webkit-transform-origin: left top 0;
    -o-transform: rotate(270deg);
    -o-transform-origin: left top 0;
    transform: rotate(270deg);
    transform-origin: left top 0;
    -moz-border-radius: 0 0 4px 4px;
    -webkit-border-radius: 0 0 4px 4px;
    border-radius: 0 0 4px 4px;
}

#tiroir_btn {
    font-family: "AvenirLight", "Avenir", "Arial";
    font-style: normal;
    font-weight: normal;
    box-sizing: content-box;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    -webkit-box-sizing: content-box;
}


#tiroir_info {
    font-family: "AvenirLight", "Avenir", "Arial";
    position: absolute;
    top: 0;
    min-height: 430px;
    width: 500px;
    background: none repeat scroll 0 0 #ffffff;
    color: #655f53;
    float: left;
    line-height: 18px;
    padding: 20px 0 20px 20px;
    z-index: 10000;
    cursor: default !important;
    display: none;
    -ms-filter: "progid:DXImageTransform.Microsoft.Shadow(Strength=5, Direction=135, Color='#c0c0c0')" !important;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.5);
    -moz-border-radius: 0 4px 4px 0;
    -webkit-border-radius: 0 4px 4px 0;
    border-radius: 0 4px 4px 0;
    font-size: 12px;
    -webkit-animation: slide 0.5s forwards;
    -webkit-animation-delay: 2s;
    animation: slide 0.5s forwards;
    animation-delay: 2s;
    font-style: normal;
    font-weight: normal;
    box-sizing: content-box;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
}
#tiroir_info.open{
    display: flex !important;
}
.close{
    opacity: 1 !important;
}

#tiroir_info #tiroir_info_divleft a {
    color:#655f53 !important;
}

#tiroir_info #tiroir_info_divleft .tiroir_info_cmcas_name,
#tiroir_info #tiroir_info_divleft .tiroir_info_cmcas_site
{
    display: block;
    font-weight: 200;
}
#tiroir_info #tiroir_info_divleft .tiroir_info_cmcas_site
{
    font-size: 12px;
}

#tiroir_info #tiroir_info_divleft span {
    font-size: 16px;
}

#tiroir_info a {
    text-decoration: none;
    cursor: pointer;
}

#tiroir_info a.tiroir_info_descrnews {
    text-decoration: none;
    color: #655f53;
}

//#tiroir_info #tiroir_info_divleft {
//    float: left;
//    padding-right: 15px;
//    width: 290px;
//}
//
//#tiroir_info #tiroir_info_divright {
//    float: left;
//    width: 180px;
//}

#tiroir_info #tiroir_info_map {
    margin-top: 20px;
    width: 180px;
    height: 180px;
}
#tiroir_info *{
    line-height: 18px !important;
}
