.btn {
  border-radius: 0px !important;
  border: none !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.navbar-toggler {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.navbar-toggler-row {
  display: block;
  height: 3px;
  width: 32px;
  background-color: #ffffff;
  margin: 4px;
}

.btn-outline-success {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

/* formulaire identification */
#identification input, #form-update_mdp input {
  width: 100%;
  padding: 5px 15px;
  line-height: 1.3em;
  border: solid 1px #BBB;
  border-radius: 4px;
  box-sizing: border-box;
  min-height: 36px;
}

#identification .sb input, #form-update_mdp .sb input {
  padding: 10px;
  border-radius: 5px;
  background: #F0AD4E;
  color: #FFF;
  border: none;
}


#bmFull {
  font-family: "AvenirLight", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
  font-size: 12px;
  line-height: 1.42857;
  background-color: #E1E1E1;
}

/* fiche od */
#fiche_contact_od {
  min-height: 400px;
}

.ficheod, .listcmcas, .setpwd {
  text-decoration: underline;
  color: #0A246A;
  cursor: pointer;
}

#fiche_contact_od p input, #fiche_contact_od p textarea {
  width: 100%;
  padding: 5px 15px;
  line-height: 1.3em;
  border: solid 1px #BBB;
  border-radius: 4px;
  box-sizing: border-box;
}

.modal-title {
  display: block;
  width: 100%;
}

#fiche_contact_od .modal-title span {
  float: right;
}

#fiche_contact_od .col-form-label {
  width: 100%;
}

#fiche_contact_od .col-form-label:first-letter {
  text-transform: uppercase;
}

#fiche_contact_od label {
  font-size: 14px !important;
}

#form-identification label, #form-identification input {
  font-size: 15px;
}

#fiche_contact_od input.form-control {
  font-size: 14px !important;
  border-bottom: 1px solid #b9b6b6 !important;
  height: 40px;
}

.mr--28 {
  margin-right: -28px;
}

.modal-title span:first-child {
  font-size: 12px;
}

.modal-title span:last-child {
  font-size: 16px;
}

.legend-title {
  font-size: 12px;
}

.sb button {
  border: 1px solid #cecece;
  border-radius: 4px;
  font-size: 12px;
  text-transform: uppercase;
  padding: 5px 15px;
  min-width: 120px;
  font-weight: bold;
}

#fiche_contact_od .sb input[type=submit], #fiche_contact_od .sb input[type=button] {
  width: auto;
}


/* LOgout Bloc**/
#bigmenu-logout {
  font-family: "AvenirLight", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
  font-size: 14px;
  line-height: 1.4em;
  text-align: left;
}

#bigmenu-logout a.mbunom {
  position: relative;
  min-width: 120px;
  padding: 5px 15px 0 30px;
  outline: none !important;
}

#bigmenu-logout #bmCompte a {
  font-size: 12px;
  line-height: 10px;
}
#bigmenu-logout #bmCompte {
  left: -10px;
  border-radius: 0px !important;
  box-shadow: 0px 2px 5px -2px;
}

#bigmenu-logout .dropdown-toggle::after {
  border-color: transparent;
  display: none;
}

#bigmenu-logout .dropdown-menu[data-bs-popper] {
  left: -8px;
}

#bigmenu-logout.mbuser a.mbunom::before {
  content: '';
  position: absolute;
  top: 15px;
  left: -10px;
  display: block;
  width: 20px;
  height: 20px;
  border-top: solid 1px #FFF;
  border-right: solid 1px #FFF;
  transform: rotate(52deg) skew(15deg);
  -webkit-transform: rotate(52deg) skew(15deg);
  -moz-transform: rotate(52deg) skew(15deg);
  -o-transform: rotate(52deg) skew(15deg);
  -ms-transform: rotate(52deg) skew(15deg);
}

.ccas-bigmenu-loading, .ccas-bigmenu-loading-background {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  border-radius: 10px;
  z-index: 99999;
  pointer-events: all;
}

.ccas-bigmenu-loading-background {
  z-index: 99998;
  display: block;
  background-color: #ccc !important;
  opacity: 0.4;
  border-radius: 0;
}

.ccas-bigmenu-loading-icon {
  top: 50%;
  position: fixed;
  left: 45%;
  width: 40px;
  z-index: 99999;
}