
.bigmenu-height > li {
  padding-top: 5px;
}

button {
  cursor: pointer !important;
}

.border {
  border: 1px solid #EEEEEE !important;
}

.border-start {
  border-left: 1px solid #EEEEEE !important;
}

.border-bottom {
  border-bottom: 1px solid #EEEEEE !important;
}

.plr-48 {
  padding-left: 48px;
  padding-right: 48px;
}

.btn-identification, .button-mon-compte {
  border-radius: 22px;
  font-size: 0.8em;
  vertical-align: middle;
  border: 2px solid $green-ccas !important;
  display: inline-block !important;
}

.btn-identification {
  font-family: "Roboto Regular", Arial, Helvetica, sans-serif !important;
  border: 2px solid $red-ccas !important;
  font-weight: bold;
  font-size: 14px !important;
  text-align: center;
  padding: 6px !important;
}

.btn-identification, .button-mon-compte {
  margin-left: 20px !important;
  min-width: 100px !important;
}

.btn-connexion, .btn-creer-compte {
  width: auto !important;
  border-radius: 22px !important;
  font-weight: bold;
  border: 1px solid;
  font-size: 14px;
  max-width: 200px;
  min-width: 188px;
  font-family: "AvenirLight", "Helvetica Neue", Helvetica, Arial, sans-serif;
  display: inline-block !important;
}

.btn-connexion, .btn-creer-compte {
  margin: 22px 0;
}

//.btn-connexion {
//    padding: 12px !important;
//}
//.btn-creer-compte {
//    padding: 12px 38px !important;
//}
.ccas-logo {
  font-size: 100%;
  cursor: pointer;
  max-width: 350px;
  img, a {
    cursor: pointer;
  }
}

.ccas-icon-user {
  cursor: pointer;

  .icon-user {
    cursor: pointer;
    margin-left: 20px;
    width: 28px;
  }
}

.enr-label {
  font-size: 2em !important;
  color: $blue-ccas !important;
}

.as-label {
  font-size: 2em !important;
  color: $blue-ccas !important;
}

@media screen and (max-width: 768px) {
  .enr-label, .as-label {
    font-size: 1.25em !important;
  }
}

@media screen and (max-width: 420px) {
  .enr-label, .as-label {
    font-size: 0.9em !important;
  }
}

@media screen and (max-width: 991px) {
  .cbr-navbar-collapse {
    position: absolute;
    width: 100%;
    top: 0px;
    background-color: #ffffff;
    left: 0px;
    z-index: 99999;
    /*z-index: 9999999999;*/
    box-shadow: 0px 5px 8px -8px;
  }

  .cbr-navbar-collapse > ul > li {
    box-shadow: 0px 2px 8px -8px;
  }

  .ccas-btn-burger {
    position: absolute !important;
    right: 12px;
  }

}

.enr-label {
  font-family: "Roboto Light", Arial, Helvetica, sans-serif !important;
}

.as-label {
  font-family: "Roboto Medium", Arial, Helvetica, sans-serif !important;
}

.menu-text-label {
  font-size: 1em !important;
  color: $black-ccas !important;
  font-family: "Roboto Bold", Arial, Helvetica, sans-serif !important;
  padding: 20px 20px !important;
}

.fw-light .enr-label {
  font-size: 2em !important;
}

.mbunom_label {
  font-size: 1em !important;
  font-family: "Roboto Regular", Arial, Helvetica, sans-serif !important;
}

.mbudeco-momcompte, .mbudeco-momcompte:hover {
  font-family: "Roboto Regular", Arial, Helvetica, sans-serif !important;
  font-size: 0.8em !important;
  color: $green-ccas !important;
  transition: ease-in-out 0.3s;
  text-decoration: none !important;
}

.mbudeco-momcompte:hover {
  color: #ffffff !important;
  background: $green-ccas !important;
}

.btn-conn-bg {
  font-family: "Roboto Regular", Arial, Helvetica, sans-serif !important;
  font-size: 14px;
  color: $red-ccas !important;
  text-decoration: none !important;
  -webkit-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
}

.btn-conn-bg:hover {
  font-family: "Roboto Regular", Arial, Helvetica, sans-serif !important;
  font-size: 14px;
  transition: ease-in-out 0.3s;
  color: #ffffff !important;
  background: $red-ccas !important;
  text-decoration: none !important;
}

.bigmenu-height {
  min-height: 80px !important;
}

.mbudeco {
  font-family: "Roboto Regular", Arial, Helvetica, sans-serif !important;
  margin: auto 0;
  display: inline-block;
  padding: 8px !important;
  //height: 26px;
}

.mbudeco-label {
  font-family: "Roboto Regular", Arial, Helvetica, sans-serif !important;
  text-decoration: none !important;
  font-size: 0.9em !important
}

.mbudeco-label:hover {
  cursor: pointer !important;
}

@media screen and (max-width: 991px) {
  .align-items-center {
    align-items: normal !important;
  }
  .justify-content-center {
    justify-content: normal !important;
  }
  .d-none-xs-sm-md {
    display: none !important;
  }
  .menu-text-label {
    padding: 6px 30px !important;
  }
}

@media screen and (min-width: 992px) {
  .d-none-lg {
    display: none !important;
  }
}


.is-identified .ccas-icon-user::before, .ccas-icon-user.connected::before {
  content: "";
  padding: 6px;
  background-color: #6C9D27;
  position: absolute;
  right: 2px;
  bottom: -4px;
  border-radius: 14px;
}

#bigmenu-logout-xs .button-mon-compte {
  display: none !important;
}

.ccas-btn-burger.btn-close {
  padding: 0 20px;
  z-index: 99999999;
  position: relative;
  font-size: 18px;
  top: 28px;
  right: 8px;
}

@media screen and (max-width: 991px) {
  .collapse:not(.show) {
    display: none !important;
  }
}
