/* hover color  #EEEEEE */
.second-nav .nav-link:hover, .second-nav .nav-link.show{
    border-bottom: 5px solid #E34923;
}
/*.second-nav .nav-link.color-orange:hover,.second-nav .nav-link.color-orange.show{*/
/*    border-bottom: 5px solid #E34923;*/
/*}*/
/*.second-nav .nav-link.color-pink:hover, .second-nav .nav-link.color-pink.show{*/
/*    border-color: #A41082;*/
/*}*/
/*.second-nav .nav-link.color-red:hover, .second-nav .nav-link.color-red.show{*/
/*    border-color: #e3004f;*/
/*}*/
/*.second-nav .nav-link.color-dark-blue:hover, .second-nav .nav-link.color-dark-blue.show{*/
/*    border-color: #161A79;*/
/*}*/
/*.second-nav .nav-link.color-light-blue:hover, .second-nav .nav-link.color-light-blue.show{*/
/*    border-color: #37B2E0;*/
/*}*/
/*.second-nav .nav-link.color-weight-blue:hover, .second-nav .nav-link.color-weight-blue.show{*/
/*    border-color: #0067a8;*/
/*}*/
/*.second-nav .nav-link.color-red:hover, .second-nav .nav-link.color-red.show{*/
/*    border-color: #e42a18;*/
/*}*/
/*.second-nav .nav-link.color-green:hover, .second-nav .nav-link.color-green.show{*/
/*    border-color: #7bac20;*/
/*}*/


