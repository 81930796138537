.second-nav .nav-link{
    padding: 5px 15px 0 15px !important;
    color: #1F1F1F !important;
    border-bottom: solid 5px #EEE;
    line-height: 30px;
    font-size: 14px;
    position: relative;
}
.second-nav ul.navbar-nav > li.nav-item{
    border-right: 1px solid #fff;
}

.second-nav .mega-dropdown .nav-link.show:before{
    content: "";
    border-bottom: 15px solid #EEEEEE;
    border-right: 17px solid transparent;
    border-left: 17px solid transparent;
    position: absolute;
    bottom: -28px;
    left: 50%; /* à 50%/50% du parent référent */
    transform: translate(-50%, -50%); /* décalage de 50% de sa propre taille */
    z-index: 1000;
}

.second-nav h1,
.second-nav .h1 {
    font-size: 36px;
    text-align: center;
    font-size: 5em;
    color: #404041;
}

.second-nav .navbar-nav>li>.dropdown-menu {
    margin-top: 0px;
    border-radius: 0;
    border-top: 15px solid #fff !important;
    z-index: 9;
    border: 0;

}
.second-nav .navbar-nav>li>.dropdown-menu li,
.second-nav .navbar-nav>li>.dropdown-menu a,
.second-nav .navbar-nav>li>.dropdown-menu h2{
    font-family: "AvenirLight", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    text-align: left;
    padding: 0px;
}
.second-nav .navbar-nav>li>.dropdown-menu .dropdown-header a,
.second-nav .navbar-nav>li>.dropdown-menu .dropdown-header h2{
    font-weight: bold;
}
.second-nav .navbar-nav>li>.dropdown-menu .dropdown-header,
.second-nav .navbar-nav>li>.dropdown-menu .dropdown-header{
    padding: 6px 0px !important;
}
.second-nav .sub-link{
    display: inline-block !important;
    padding: 0px 15px !important;
    color: #1F1F1F !important;
    border-bottom: solid 5px #EEE;
    text-transform: uppercase;
    text-decoration: none;
}

.second-nav .navbar-default .navbar-nav>li>a {
    width: 200px;
    font-weight: bold;
}

.second-nav .mega-dropdown {
    position: static !important;
}

.second-nav .mega-dropdown-menu {
    padding: 12px;
    width: 100%;
    box-shadow: none;
    border-bottom: 12px solid transparent;
    -webkit-box-shadow: none;
    padding-bottom: 0;
}

.second-nav .mega-dropdown-menu.show {
    display: flex !important;
}
.second-nav .mega-dropdown-menu > li {
    float: left;
}


.second-nav .mega-dropdown-menu > li > ul {
    padding: 0;
    margin: 0;
}

.second-nav .mega-dropdown-menu > li > ul > li {
    list-style: none;
}

.second-nav .mega-dropdown-menu > li > ul > li > a {
    display: block;
    padding: 3px 20px;
    clear: both;
    font-weight: normal;
    line-height: 1.428571429;
    color: #999;
    white-space: normal;
}

.second-nav .mega-dropdown-menu > li ul > li > a:hover,
.second-nav .mega-dropdown-menu > li ul > li > a:focus {
    text-decoration: none;
    color: #444;
    background-color: #f5f5f5;
}

.second-nav .mega-dropdown-menu .dropdown-header {
    color: #428bca;
    font-size: 18px;
    font-weight: bold;
}

.second-nav .mega-dropdown-menu form {
    margin: 3px 20px;
}

.second-nav .mega-dropdown-menu .form-group {
    margin-bottom: 3px;
}

.second-nav .filet-top, .second-nav .filet-bottom{
    height: 6px;
    width: 100%;
    display: block;
    background-color: #E34923;
    position: absolute;
    left: 0;
    right: 0;

}
.second-nav .filet-top{
    opacity: 0.5;
}
.second-nav .filet-bottom{
    margin-top: 6px;
    height: 15px;
}
.second-nav .dropdown-footer{
    height: 22px;
}
.ccas-bigmenu-dropdown-menu{
    left: 0;
}

/*.dropdown:hover .dropdown-menu {*/
/*    display: block !important;*/
/*    margin-top: 0 !important;*/
/*    left: 0 !important;*/
/*}*/