/* orange color  #E34923 */
.bg-orange{
    background-color: #E34923 !important;
}
.color-orange{
    color: #E34923 !important;
}
.border-color-orange{
    border-color: #E34923 !important;
}
/* orange color  #F0AD4E */
.bg-light-orange{
    background-color: #F0AD4E !important;
}
.color-light-orange{
    color: #F0AD4E !important;
}
.border-color-light-orange{
    border-color: #F0AD4E !important;
}
/* pink color  #ffb400; */
.bg-light-2-orange{
    background-color: #ffb400 !important;
}
.color-light-2-orange{
    color : #ffb400 !important;
}
.border-light-2-orange{
    border-color: #ffb400 !important;
}


/* green color  #6C9D27 */
.bg-green{
    background-color: #6C9D27 !important;
}
.color-green{
    color: #6C9D27 !important;
}
.border-color-green{
    border-color: #6C9D27 !important;
    border: 1px solid !important;
}
/* green color  #80B152 */
.bg-light-green{
    background-color: #80B152 !important;
}
.color-light-green{
    color: #80B152 !important;
}
.border-color-light-green{
    border-color: #80B152 !important;
}


/* white color  #ffffff */
.bg-white{
    background-color: #ffffff !important;
}
.color-white{
    color : #ffffff !important;
}
.border-color-white{
    border-color: #ffffff !important;
}
/* white color  #EEEEEE */
.bg-gray-white{
    background-color: #EEEEEE !important;
}
.color-gray-white{
    color : #EEEEEE !important;
}
.border-color-gray-white{
    border-color: #EEEEEE !important;
}

/* pink color  #D9004F */
.bg-pink{
    background-color: #D9004F !important;
}
.color-pink{
    color : #D9004F !important;
}
.border-color-pink{
    border-color: #D9004F !important;
}

/* pink color  #9C0000FF */
.bg-red{
    background-color: #9C0000FF !important;
}
.color-red{
    color : #9C0000FF !important;
}
.border-color-red{
    border-color: #9C0000FF !important;
}

/* pink color  #D9004F */
.bg-blue{
    background-color: #0066cc !important;
}
.color-blue{
    color : #0066cc !important;
}
.border-color-blue{
    border-color: #0066cc !important;
}

/* pink color  #D9004F */
.bg-light-blue{
    background-color: #0066cc !important;
}
.color-light-blue{
    color : #0066cc !important;
}
.border-light-color-blue{
    border-color: #0066cc !important;
}

/* pink color  #D9004F */
.bg-dark-gray{
    background-color: #333333 !important;
}
.color-dark-gray{
    color : #333333 !important;
}
.border-color-dark-gray{
    border-color: #333333 !important;
}


/* green Color button of  submit fiche OD  #26682D*/
.bg-dark-green{
    background-color: #26682D !important;
}

.color-dark-green{
    color : #26682D !important;
}
.border-color-dark-green{
    border-color: #26682D !important;
}


$blue-ccas : #0066cc;
$black-ccas : #333333;
$red-ccas : #cc3366;
$green-ccas : #339933;
$gray-ccas: #EEEEEE;