@font-face {
    font-family: 'AvenirLight';
    src: url('fonts/AvenirBook.eot');
    src: url('fonts/AvenirBook.eot?#iefix') format('embedded-opentype'),
    url('fonts/AvenirBook.woff2') format('woff2'),
    url('fonts/AvenirBook.woff') format('woff'),
    url('fonts/AvenirBook.ttf') format('truetype'),
    url('fonts/AvenirBook.svg#AvenirBook') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Roboto Medium';
    src: url("fonts/Roboto-Medium.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
  font-family: 'Roboto Light';
  src: url("fonts/Roboto-Light.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto Bold';
  src: url("fonts/Roboto-Bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto Regular';
  src: url("fonts/Roboto-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
body{
  margin: 0;
}
#ccas-bloc-bigmenu {

  .container{
    max-width: 1140px !important;
    font-family: "AvenirLight", "Helvetica Neue", Helvetica, Arial, sans-serif;
  }
  @media (min-width: 576px) {
    .container {
      padding: 0 30px !important;
    }
  }
  font-size:  16px ;
  .ccas-copirght-footer {
    font-family: 'Roboto Regular';
  }
  * {
    margin: 0;
    padding: 0;
    border: 0;
    //font-family: "Roboto Bold", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 100%;
    font-weight: normal;
    font-style: normal;
    text-decoration: none;
    outline: none !important;
    line-height: normal;
    clear: both;
    float: unset;
    width: auto;
    background-color: transparent;
  }
  //font-size: 1em !important;
  .row{
    display: flex;
  }
  .modal .modal-content{
    border: 1px solid #EEEEEE !important;
  }
  h1.ccas-logo{
    background-color: #ffffff !important;
  }
  @import './bootstrap.5.2.0.scss';
  @import './main.scss';
  @import './color.scss';
  @import './modal.scss';
  @import './bouton.scss';
  @import './second-nav.scss';
  @import './color-hover.scss';
  @import './favoris.scss';
  @import './tiroir.scss';
  @import './composant-authent.scss';
  @import './alignement.scss';
  @import './navbar-refonte.scss';
  @import './footer.scss';
  @import './arrow.scss';
  @import './accessibilite.scss';
  .btn-close {
    margin-top: -16px !important;
    margin-right: -16Px !important;
    background-color: #E1E1E1;
    opacity: 1;
    border-radius: 16px;
    border: 2px solid #959393;
    font-size: 14px;
    font-weight: bold;
  }
  .btn-close:hover {
    color: #fff;
    text-decoration: none;
    opacity: 1;
  }
}

