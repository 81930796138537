.bmComposantAuthent {
    border:thin solid #c46b08;
    padding : 5px;
    margin: 8px;
}
.bmComposantAuthent .blocs{
    border:thin solid green;
    padding : 5px;
    margin: 5px;
}
.bmComposantAuthent .liens{
    border:thin solid green;
    margin: 5px;
}
.bmComposantAuthent .blocs  > a{
    border:thin solid deeppink;
    padding : 5px;
    margin: 8px;
}
.bmComposantAuthent .liens span {
    border:thin solid #cba77e;
    padding : 1px;
    margin: 8px;
    width: 300px;
    height:60px;
    background-color: #cbd3d5;
}
.bmComposantAuthent .liens span A {
    text-decoration: none;
    color: #9C1A1A;
}