.view_favoris {
    position: absolute !important;
    right: 0;
    width: 48px;
    background-position: center;
    background-repeat: no-repeat;
    border-bottom: inherit !important;
    padding: 5px 8px !important;
    text-align: center;
}

.view_favoris:hover, .btn-detail-favoris:hover {
    opacity: 0.5;
}

.view_favoris_txt {
    display: none;
}

.content-favoris {
    position: relative;
    width: auto;
    height: 110px;
    border-style: solid;
    border-width: 1px;
    border-color: rgb(204, 204, 204);
}

.content-favoris .btn-detail-favoris {
    position: absolute;
    bottom: 0px;
    right: 0px;
    z-index: 2;
    padding: 10px 11px 10px 15px;
    border-radius: 0px;
    text-decoration: none;
    font-size: 12px;
    text-align: left;
    /*background-image: url("https://ccas-qualif.ccas.fr/application_ccasfr/views/images/arrow_white_18x18.png");*/
    background-position: right 5px center;
    background-repeat: no-repeat;
}

.content-favoris .btn-delete-favoris {
    position: absolute;
    top: 4px;
    right: 4px;
    z-index: 2;
    line-height: 20px;
    font-size: 24px;
    cursor: pointer;
    color: rgb(71, 76, 75);
    background: none;
    border: none;
    padding: 0;
}

.content-favoris .btn-delete-favoris:hover {
    color: #E34923;
}

.content-favoris .legend {
    padding: 10px 25px 10px 5px;
    z-index: 2;
    color: #e95d0f;
    line-height: 20px;
    font-size: 16px;
    font-weight: bold;
    background-color: rgb(255, 255, 255);
}

.content-favoris .sousLegende {
    color: #474c4b;
    font-size: 12px;
    font-weight: bold;
}

.content-favoris .details {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 2;
    padding: 10px 20px 10px 15px;
    color: #fff;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0;
    text-decoration: none;
    font-size: 12px;
    text-align: left;
    /*width: isSmall ?*/
    /*116px: 80px;*/
    background-position: right 5px center;
    background-repeat: no-repeat;
    background-color: #e95d0f
}

.code-favoris {
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 2;
    width: auto;
    line-height: inherit;
    padding: 10px;
    text-align: center;
    border-radius: 0px;
    font-size: 12px;
}

#favoris_msg .modal-title span {
    font-size: 14px;
}

.favorisModalBody .item {
    /*   height: 104px;*/
    margin-bottom: 5px;
}

.favorisModalBody .item:last-child {
    margin-bottom: 0px;
}

.content-favoris > div {
    position: relative;
}

.content-favoris .imageFavoris {
    position: relative;
    width: 100%;
}

.favorisModalBody .code {
    position: absolute;
    padding: 6px 10px;
    width: 100%;
    left: 0;
    background-color: rgba(56, 56, 1, .5);
}

.favorisModalBody .code.vert {
    background-color: #6b9e28;
}

.favorisModalBody .code.rouge {
    background-color: #d83633;
}

/* tarif pmr*/
.favorisModalBody .code.violet {
    background-color: #c22fc8;
}

/*tarif socialisé "partenaire"*/
.favorisModalBody .code.pink {
    background-color: #c36597;
}

/*tarif négocié  "bonplan"*/
.favorisModalBody .code.light-pink {
    background-color: #e6a4c0;
}